import React from "react";
import App from "./App";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  COGNITO_APP_CLIENT_ID,
  COGNITO_REGION,
  COGNITO_USER_POOL_ID,
} from "./config";

import Amplify from "@aws-amplify/core";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "../src/msalConfig";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,

    region: COGNITO_REGION,

    userPoolId: COGNITO_USER_POOL_ID,

    userPoolWebClientId: COGNITO_APP_CLIENT_ID,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <MsalProvider instance={msalInstance}>

<App />
  </MsalProvider>

);
