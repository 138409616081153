export const API_COLLATICA_URL = process.env.REACT_APP_COLLATICA_API_ENDPOINT;
export const API_XBI4_URL = process.env.REACT_APP_XBI4_API_ENDPOINT;
export const API_URL_UPDATED = process.env.REACT_APP_API_ENDPOINT_UPDATED;

export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;

export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;

export const COGNITO_APP_CLIENT_ID =
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
  export const API_URL = process.env.REACT_APP_API_X_ENDPOINT;
export const API_XBI4_OLD_EMPLOYEE_URL = process.env.REACT_APP_API_OLD_XBI4_E_ENDPOINT