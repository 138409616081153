import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "74a9c897-22eb-4468-8e07-b2d374c192c2", // From Azure App Registration
    authority: "https://login.microsoftonline.com/f49970f7-1e45-49a2-b37b-8862c10b3ff5",
    // https://portal.xbi4.com
    redirectUri: "https://portal.insytix.com", // AWS URL
  },
};
// Function to authenticate the user and get the access token
// export const authenticateUser = async () => {
//   const loginRequest = { scopes: ["Calendars.ReadWrite", "Mail.Send", "User.Read"] };

//   try {
//     const loginResponse = await msalInstance.loginPopup(loginRequest);
//     return loginResponse.accessToken; // Return the access token
//   } catch (error) {
//     console.error("Error during authentication:", error);
//     return null;
//   }
// };

export const msalInstance = new PublicClientApplication(msalConfig);
