import  { useEffect } from "react";

import "./App.css";
function Index() {
  useEffect(() => {
    
    const timer = setTimeout(() => {
      window.location.replace("https://portal.insytix.com/"); // Redirect after the timer
    }, 2000); 

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="loading-container">
    <div className="loading-content">
      <div className="spinner"></div>
      <h2>Insytix portal is loading...</h2>
    </div>
  </div>
    // <AuthContextProvider>
    //   <RouterComp />

      
    // </AuthContextProvider>
    // <div className="main_app">
    //   <img
    //     src="https://c8.alamy.com/comp/2B77M01/3d-web-page-under-construction-concept-2B77M01.jpg"
    //     alt="under maintanence"
    //   />
    // </div>
  );
}

export default Index;

// const doc = new jsPDF({
//   orientation: "landscape",
//   unit: "px",
//   format: "a4",
// });

// doc.addImage(
//   canvas.toDataURL("image/png"),
//   "PNG",
//   0,
//   0,
//   doc.internal.pageSize.getWidth(),
//   doc.internal.pageSize.getHeight()
// );

// doc.save("document.pdf");
